import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSpaceSeparator'
})
export class ThousandSpaceSeparatorPipe implements PipeTransform {

  transform(value: number | string): string {
    if (!value && value !== 0) {
      return '';
    }

    return value.toString().replace(/,/g, ' ');
  }
}
