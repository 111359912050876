import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ResponsiveService } from '@rex/shared/services/responsive.service';
import { Router } from '@angular/router';
import { environment } from '@rex/environments/environment';
import { LandingLanguageService } from '@rex/landing/services/landing-language.service';

@Component({
  selector: 'global-footer',
  templateUrl: 'main-panel-footer.container.html',
  styleUrls: ['main-panel-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainPanelFooterComponent {
  constructor(public responsiveService: ResponsiveService, private router: Router, private landingLanguageService: LandingLanguageService) {
  }

  @Input()
  useRouteLink:boolean = true;

  navigate(page: string, fragment: string|null = null) {
    if (this.useRouteLink) {
      let translatedUri = this.landingLanguageService.getTranslatedUrl('/' + page);

      this.router.navigate([translatedUri], { fragment: fragment });
    } else {
      window.location.assign(this.getLandingUrl(page, fragment));
    }
  }

  getLandingUrl(page: string, fragment: string|null) {
    let url = environment.urls.lp + '/' + page;

    if (fragment) {
      url = url + '#' + fragment
    }

    return url;
  }

  getSignInUrl(): string {
    return environment.urls.rex + '/signin';
  }
}
