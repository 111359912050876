<div class="bg-transparent lg:bg-white right-box sticky-aside">
  <div class="row" *ngIf="!this.isMobile">
    <div class="col-12 pl-0">
      <p class="p-0 small-attribute"> {{ toLocalized(offer.position).getContentForLang(activeLanguage) }}</p>
    </div>
    <div class="col-12 mt-4 mb-6 pl-0">
      <jb-salaries-presenter class="subtitle" [salaries]="offer.salaries" [iconMaxWidth]="'60'"></jb-salaries-presenter>
    </div>
  </div>

  <div *ngIf="offer.company_name">
    <div class="flex items-center small-attribute">
      <global-ui-icon [icon]="'building'" [color]="'gray'"></global-ui-icon>
      <label class="ml-2">{{ 'jobboard::index.company' | translate }}</label>
    </div>
    <p class="mt-1 right-box-title pl-6">{{ offer.company_name }}</p>
  </div>

  <div *ngIf="offer.localizations && offer.localizations.length" class="mt-4">
    <div class="flex items-center small-attribute">
      <global-ui-icon [icon]="'marker'" [color]="'gray'" [size]="1"></global-ui-icon>
      <label class="ml-2">{{ 'jobboard::index.localization' | translate }}</label>
    </div>
    <p class="mt-1 right-box-title pl-6">
      <jb-localizations-display [localizations]="offer.localizations"></jb-localizations-display>
    </p>
  </div>

  <div *ngIf="offer.industries && offer.industries.length" class="mt-4">
    <div class="flex items-center small-attribute">
      <global-ui-icon [icon]="'suitcase'" [color]="'gray'" [size]="1"></global-ui-icon>
      <label class="ml-2">{{ 'jobboard::index.industry' | translate }}</label>
    </div>
    <p class="mt-1 right-box-title pl-6">
      <ng-container *ngFor="let industry of offer.industries; let isLast=last">
        {{ industry }}{{isLast ? '' : ', '}}
      </ng-container>
    </p>
  </div>

  <div *ngIf="offer.experience_in_years" class="mt-4">
    <div class="flex items-center small-attribute">
      <global-ui-icon [icon]="'star'" [color]="'gray'" [size]="1"></global-ui-icon>
      <label class="ml-2">{{ 'jobboard::index.experience' | translate }}</label>
    </div>
    <p class="mt-1 right-box-title pl-6" translate [translateParams]="{ years: offer.experience_in_years }">experience_in_years</p>
  </div>

  <div *ngIf="offer.working_time" class="mt-4">
    <div class="flex items-center small-attribute">
      <global-ui-icon [icon]="'history'" [color]="'gray'" [size]="1"></global-ui-icon>
      <label class="ml-2">{{ 'jobboard::index.work_type' | translate }}</label>
    </div>
    <p class="mt-1 right-box-title pl-6" translate>{{ 'enum.type_of_work_time.' + offer.working_time }}</p>
  </div>

  <div *ngIf="offer.types_of_agreement && offer.types_of_agreement.length" class="mt-4">
    <div class="flex items-center small-attribute">
      <global-ui-icon [icon]="'doc'" [color]="'gray'" [size]="1"></global-ui-icon>
      <label class="ml-2">{{ 'jobboard::index.agreement' | translate }}</label>
    </div>
    <p class="mt-1 right-box-title pl-6" translate>
      <ng-container *ngFor="let typesOfAgreement of offer.types_of_agreement; let isLast=last">
        {{ typesOfAgreement.value }}{{isLast ? '' : ', '}}
      </ng-container>
    </p>
  </div>

  <div class="mt-8" *ngIf="showApplyButton && !this.isMobile">
    <global-apply-button [href]="offer.application_url"></global-apply-button>
  </div>

  <ng-content></ng-content>
</div>
