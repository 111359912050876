import { Component, ContentChild, ViewEncapsulation, HostListener, Input } from '@angular/core';
import { FormControlName } from '@angular/forms';

import { Control } from './control';

@Component({
  selector: 'global-ui-form-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'global-ui-form-group',
    '[class.global-ui-form-group--plain]': 'plain',
    '[class.global-ui-form-group--clear]': 'clear',
    '[class.global-ui-form-group--focused]': 'focused',
    '[class.global-ui-form-group--invalid]': '!valid && !focused',
    '[class.global-ui-form-group--filled]': 'valid && !empty && !focused',
  },
})
export class GroupComponent {
  /**
   * @var {boolean}
   */
  @Input()
  get plain(): boolean {
    return this._plain;
  }
  set plain(value: boolean) {
    this._plain = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input()
  get clear(): boolean {
    return this._clear;
  }
  set clear(value: boolean) {
    this._clear = value !== false;
  }

  @Input('showLockIcon')
  showLockIcon: boolean = true;

  @Input('loader')
  loader: boolean = true;

  @Input('forceShowError')
  forceShowError: boolean = false;

  @Input('shownErrorOnInput')
  shownErrorOnInput: boolean = false;

  /**
   * @var {Control}
   */
  @ContentChild(Control, { static: false })
  control: Control;

  /**
   * @var {FormControlName}
   */
  @ContentChild(FormControlName, { static: false })
  ngControl: FormControlName;


  @Input('labelExtraLine')
  labelExtraLine: boolean = false

  /**
   * @var {boolean}
   */
  get empty(): boolean {
    if (this.ngControl === undefined) return true;

    return this.ngControl.value == null || this.ngControl.value == '';
  }

  /**
   * @var {boolean}
   */
  get valid(): boolean {
    if (this.ngControl === undefined) return true;

    return !(this.ngControl.invalid && this.ngControl.dirty);
  }

  /**
   * @var {any}
   */
  get error(): any {
    const key = Object.keys(this.ngControl.errors)[0];

    if (key == null) return null;

    return { key, ...this.ngControl.errors[key] };
  }

  /**
   * @var {boolean}
   */
  get locked(): boolean {
    if (this.ngControl === undefined) return false;

    return this.ngControl && this.ngControl.disabled;
  }

  get loading(): boolean {
    if (this.ngControl === undefined) return false;

    return this.ngControl && this.ngControl.pending;
  }

  /**
   * @var {boolean}
   */
  get focused(): boolean {
    return this.control && this.control.focused;
  }

  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    if (this.valid) return false;

    if (!this.control) return false;

    if (this.forceShowError) return true;

    return this.control.canShowValidationMessage;
  }

  /**
   * @var {boolean}
   */
  protected _plain: boolean = false;

  /**
   * @var {boolean}
   */
  protected _clear: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Focus on the control.
   *
   * @return void
   */
  @HostListener('click')
  focus(): void {
    this.control.focusin();
  }
}
