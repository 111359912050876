import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LanguageModel } from '@rex/job-board/models/language.model';

@Component({
  selector: 'jb-language-skill',
  templateUrl: 'language-skill.component.html',
  styleUrls: ['language-skill.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LanguageSkillComponent {
  starMax = Array(7).fill(0).map((_, i) => i + 1);

  @Input()
  language: LanguageModel

  getStarColor(starIndex: number, language: LanguageModel) {
    if(starIndex <= this.getNumberByLevel(language.attributes.level)) {
      return 'yellow';
    }

    return 'gray-light';
  }

  getNumberByLevel(level: string): number {
    switch (level) {
      case 'A1':
        return 1;
      case 'A2':
        return 2;
      case 'B1':
        return 3
      case 'B2':
        return 4;
      case 'C1':
        return 5;
      case 'C2':
        return 6;
      case 'native':
        return 7;
    }
  }
}
