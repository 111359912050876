<div class="relative">
  <div
    (click)="toggleDropdown()"
    [ngClass]="{'rounded-t-lg shadow-lg open-heading-text': isOpen, 'closed-heading-text	': !isOpen}"
    [class]="'flex items-center justify-between cursor-pointer transition-all border-none ' + customClass"
  >
    <div class="flex items-center gap-2">
      <global-ui-icon [icon]="'world'" [width]="'1rem'" [color]="isOpen ? 'black' : 'gray'"></global-ui-icon>
      <span class="heading-text">{{ code | translate }}</span>
      <global-ui-icon [icon]="'chevron-down'" [color]="isOpen ? 'black' : 'gray'" [size]="0.413" [ngClass]="{'transform rotate-180': isOpen}" class="transition-transform"></global-ui-icon>
    </div>
  </div>
  <div [ngClass]="{'hidden': !isOpen}" class="absolute left-0 right-0 bg-white rounded-b-lg shadow-lg overflow-hidden transition-all px-2 pb-2">
    <ng-container *ngFor="let option of this.sortOptions">
      <button (click)="selectOption(option)" class="select-text gap-3 p-2 w-full uppercase">{{ option.code | translate }}</button>
    </ng-container>
  </div>
</div>
