import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: "https://a843693323c04727bdf69d320ae77bb1@sentry-prod.recruitmentexchange.dev//2",
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ["https://rex.hr/api"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('ngsw-worker.js');
    }
  }).catch((err) => console.error(err));


const currentUrl = new URL(window.location.href);
if (currentUrl.hostname === 'app.recruitmentexchange.net') {
  const newUrl = `https://app.rex.hr${currentUrl.pathname}${currentUrl.search}${currentUrl.hash}`;
  window.location.href = newUrl;
}
