<div [ngClass]="{'global-ui-form-group__label': true,
                   'extra-line': labelExtraLine
   }">
  <ng-content select="[global-ui-label]"></ng-content>
</div>
<div class="global-ui-form-group-control">
  <div class="global-ui-form-group-control__wrapper">
    <ng-content></ng-content>
  </div>
  <div class="global-ui-form-group-control__addon" *ngIf="showLockIcon">
    <ng-content select="[global-ui-form-addon]" *ngIf="!locked"></ng-content>
  </div>

  <div class="global-ui-form-group-control__addon" *ngIf="loader">
    <ng-content select="[global-ui-form-addon]" *ngIf="!loading"></ng-content>
  </div>
</div>

