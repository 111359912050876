import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { toLocalized } from '../../../../../../../libs/utilities/src/lib/form/localized-content/toLocalized';
import { ResponsiveService } from '@rex/shared/services/responsive.service';

@Component({
  selector: 'jb-offer-main-section',
  templateUrl: 'main-section.component.html',
  styleUrls: ['../../pages/job-offer.page.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'job-offer-page',
  },
})
export class MainSectionComponent {
  @Input()
  offer = null;

  @Input()
  showAdsBanners: boolean = true;

  @Input()
  mainDataClass: string = '';

  displaySkillsCount: number = 8;

  showMoreSkills() {
    this.displaySkillsCount = this.offer.skills.length;
  }

  showMoreSkillsItemsCount() {
    return this.offer.skills.length - this.displaySkillsCount;
  }


  public activeLanguage = this.translator.currentLang;

  /**
   * Create a new instance.
   */
  constructor(
    protected translator: TranslateService,
    public responsiveService: ResponsiveService
  ) {
  }

  readonly toLocalized = toLocalized;
}
