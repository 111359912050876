import { NgModule } from '@angular/core';
import { CommonModule } from '@rex/common';
import { GlobalCoreModule } from '@rex/shared/global/src/lib/core/global-core.module';
import { GlobalDropdownModule } from '@rex/shared/global/src/lib/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { GoogleMapComponent } from '@rex/job-board/components/google-map.component';
import { SalariesPresenter } from '@rex/job-board/components/salaries.presenter';
import { LocalizationsPresenter } from '@rex/job-board/components/localizations.presenter';
import { BannerComponent } from '@rex/job-board/components/banner.component';
import { LanguageSkillComponent } from '@rex/job-board/components/language-skill.component';
import { SkillsStarsComponent } from '@rex/job-board/components/skills-stars.component';
import { TooltipComponent } from '@rex/job-board/components/tooltip.component';
import { LocalizationsDisplayComponent } from '@rex/job-board/components/localizations.component';
import { IconComponent } from '@rex/shared/global/src/lib/core/icon/icon.component';
import { AdditionalSectionComponent } from '@rex/job-board/components/job-offer-compoents/additional-section.component';
import { MainSectionComponent } from '@rex/job-board/components/job-offer-compoents/main-section.component';
import { LanguageSwitcherComponent } from '@rex/shared/global/components/language-switcher.component';
import { MainPanelFooterComponent } from '@rex/shared/global/pages/panel/main-panel-footer.component';

const PUBLIC_API = [
  MainSectionComponent,
  AdditionalSectionComponent,
  IconComponent,
  LocalizationsDisplayComponent,
  TooltipComponent,
  SkillsStarsComponent,
  LanguageSkillComponent,
  BannerComponent,
  LocalizationsPresenter,
  SalariesPresenter,
  GoogleMapComponent,
  LanguageSwitcherComponent,
  MainPanelFooterComponent
]

const PRIVATE_API = [

]

@NgModule({
  declarations: [...PUBLIC_API, ...PRIVATE_API],
  imports: [CommonModule, TooltipModule, OverlayPanelModule, GlobalCoreModule, GlobalDropdownModule],
  exports: [...PUBLIC_API],
  providers: []
})
export class GlobalSharedModule {

}
