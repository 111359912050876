import { Component, Input, ViewEncapsulation } from '@angular/core';
import { toLocalized } from '../../../../../../../libs/utilities/src/lib/form/localized-content/toLocalized';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'jb-offer-additional-section',
  templateUrl: 'additional-section.component.html',
  styleUrls: ['../../pages/job-offer.page.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'job-offer-page',
  },
})
export class AdditionalSectionComponent {
  @Input()
  offer = null;

  @Input()
  showApplyButton: boolean = true;

  @Input()
  isMobile: boolean = false;

  public activeLanguage = this.translator.currentLang;

  /**
   * Create a new instance.
   */
  constructor(
    protected translator: TranslateService
  ) {
    //
  }

  readonly toLocalized = toLocalized;
}
