import { Component, Input } from '@angular/core';

@Component({
  selector: 'jb-localizations-display',
  templateUrl: './localizations.component.html'
})
export class LocalizationsDisplayComponent {
  /**
   * @var {any}
   */
  @Input()
  showAsTooltip: boolean = false;

  /**
   * @var {any}
   */
  @Input()
  localizations: any = null;

  /**
   * @var {boolean}
   */
  @Input()
  limit: boolean

  getGroupedLocalization()
  {
    return this.localizations.reduce(function (groups, element) {
      let groupBy = (element['type']);

      (groups[groupBy] ? groups[groupBy] : (groups[groupBy] = [])).push(element);
      return groups;
    }, {});
  }

  getCityCounts()
  {
    let cityCounts = 0;

    this.localizations.reduce(function (groups, element) {
      if (element['type'] == 'city') {
        cityCounts = ++cityCounts;
      }
    }, {});

    return cityCounts;
  }


  getCities()
  {
    if (!this.localizations) return null;

    let cities = [];

    this.localizations.reduce(function (groups, element) {
      if (element['type'] == 'city') {
        let cityDescription;

        element.json.forEach(function (value) {
          if (value.type == 'city') {
            cityDescription = value.name;
          }
        });

        cities.push(cityDescription);
      }

    }, {});

    if (this.limit) {
      return [cities[0]]
    }
    return cities;
  }

}
