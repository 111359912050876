import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SegmentButtonComponent, SegmentComponent } from '@rex/shared/global/src/lib/core/segment/segment';

const PUBLIC_API = [
  SegmentComponent,
  SegmentButtonComponent
]

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API]
})
export class GlobalSegmentModule {}
