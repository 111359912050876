import { GlobalIconModule } from '@rex/shared/global/src/lib/core/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from '@rex/shared/global/src/lib/core/logo/logo.component';
import { GlobalButtonModule } from '@rex/shared/global/src/lib/core/button/global-button.module';
import { GlobalFlagModule } from '@rex/shared/global/src/lib/core/flag/global-flag.module';
import { ThousandSpaceSeparatorPipe } from '@rex/shared/global/src/lib/core/thousand-space-separator.pipe';
import { GlobalBadgeModule } from '@rex/shared/global/src/lib/core/badge/global-badge.module';
import { SearchbarComponent } from '@rex/shared/global/src/lib/core/common/searchbar.component';
import { IconModule } from '@a3l/utilities';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalFormModule } from '@rex/shared/global/src/lib/form/global-form.module';
import { GlobalSegmentModule } from '@rex/shared/global/src/lib/core/segment/global-segment.module';
import { CommonModule as Rex } from '@rex/common';

const PUBLIC_API = [
  LogoComponent,
  ThousandSpaceSeparatorPipe,
  SearchbarComponent
];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule, IconModule, ReactiveFormsModule, TranslateModule, GlobalFormModule, GlobalSegmentModule, CommonModule, CommonModule, Rex, GlobalIconModule],
  exports: [...PUBLIC_API, GlobalIconModule, GlobalButtonModule, GlobalFlagModule, GlobalBadgeModule, GlobalSegmentModule],
})
export class GlobalCoreModule {}
