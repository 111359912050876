<ng-container *ngIf="salaries.main_salary as salary" class="jb-salaries-presenter">
  <div class="flex items-center justify-start">
    <p class="m-auto ml-0 mr-2.5">
      {{ salary.min | number:'1.0-0' | thousandSpaceSeparator }} {{' - '}}
      {{ salary.max | number:'1.0-0' | thousandSpaceSeparator }} {{ ' ' }}
      {{ salary.currency | uppercase }}
    </p>
    <div *ngIf="salaries.all_salaries && salaries.all_salaries.length" class="flex items-center jb-salaries-info">
      <global-ui-icon *ngIf="salaries.all_salaries.length === 1" [icon]="'info'" (mouseenter)="salaryOverlayPanel.show($event)" (mouseleave)="salaryOverlayPanel.hide()" color="gray-light" class="cursor-pointer rounded-full flex items-center justify-center font-bold img-max-width-{{ iconMaxWidth }}" [ngClass]="iconSize"></global-ui-icon>
      <button *ngIf="salaries.all_salaries.length > 1" (mouseenter)="salaryOverlayPanel.show($event)" (mouseleave)="salaryOverlayPanel.hide()" global-button color="grayblack" class="rounded-full flex items-center justify-center font-bold" [ngClass]="iconSize + ' ' + fontSize + ' ' + paddingClass">
        +{{ salaries.all_salaries.length -1 }}
      </button>
      <p-overlayPanel #salaryOverlayPanel [styleClass]="'salary-overlay'" class="p-0">
        <div *ngFor="let salary of salaries.all_salaries; let isLast = last" class="salary_info_box" [ngClass]="!isLast ? 'mb-5' : null">
          <p class="title">{{ 'jobboard::index.salary_info.contract_type.' + salary.contract_type | translate }} <span *ngIf="salary.period">({{'jobboard::index.salary_info.period.' + salary.period | translate }}):</span></p>
          <p class="subtitle">
            {{ salary.min | number:'1.0-0' | thousandSpaceSeparator }} {{' - '}}
            {{ salary.max | number:'1.0-0' | thousandSpaceSeparator }} {{ ' ' }}
            {{ salary.currency | uppercase }}
            <span translate>{{ salary.is_brutto ? 'jobboard::index.salary_info.gross' : 'jobboard::index.salary_info.net'}}</span>
          </p>
        </div>
      </p-overlayPanel>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!salaries.main_salary">
  <p>{{ 'jobboard::index.to_agreed' | translate }}</p>
</ng-container>
