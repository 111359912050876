import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'jb-tooltip',
  templateUrl: 'tooltip.component.html',
  styleUrls: ['tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'jp-tooltip'
  }
})
export class TooltipComponent {
  @Input()
  fontSize: string;

  @Input()
  iconSize: string|number = 0.875;

  @Input()
  icon: string;

  @Input()
  data: any;

  @Input()
  color: 'gray' = 'gray';

  constructor(private translate: TranslateService) {}

  getDataString(): string {
    const translatedItems = this.data.map(item => this.translate.instant(item));
    return translatedItems.join(', ');
  }
}
