import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'global-flag',
  template: '',
  styleUrls: ['./flag.component.scss'],
  host: {
    class: 'global-flag global-flag',
    '[class]': "'global-flag global-flag-' + code",
    '[class.global-flag--sm]': 'small',
    '[class.global-flag--lg]': 'large',
  },
  encapsulation: ViewEncapsulation.None,
})
export class FlagComponent {
  /**
   * @var {string}
   */
  @Input()
  get code(): string {
    return this._code.toUpperCase();
  }
  set code(value: string) {
    this._code = value;
  }

  /**
   * @var {boolean}
   */
  @Input()
  get small(): boolean {
    return this._small;
  }
  set small(value: boolean) {
    this._small = value !== false;
  }

  /**
   * @var {boolean}
   */
  protected _small: boolean = false;

  /**
   * @var {boolean}
   */
  @Input()
  get large(): boolean {
    return this._large;
  }
  set large(value: boolean) {
    this._large = value !== false;
  }

  /**
   * @var {boolean}
   */
  protected _large: boolean = false;


  /**
   * @var {string}
   */
  protected _code: string;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
