import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Salaries } from '@rex/job-board/models/salaries.model';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'jb-salaries-presenter',
  templateUrl: 'salaries.presenter.html',
  styleUrls: ['salaries.presenter.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'jb-salaries-presenter',
  }
})
export class SalariesPresenter {
  @Input()
  salaries: Salaries

  @Input()
  iconSize: string = 'w-8 h-8';

  @Input()
  fontSize: string = ' text-sm';

  @Input()
  paddingClass: string = ' p-4';

  @Input()
  iconMaxWidth: '60' | '100' = '100';
  /**
   * @var {boolean}
   */
  focused: boolean = false;

  toggleOverlayPanel(overlayPanel: OverlayPanel) {
    if (!overlayPanel.overlayVisible) {
      this.focused = true;
    }

    overlayPanel.toggle(event);
  }
}
