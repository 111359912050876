import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  private mobileable: boolean = window.innerWidth < 992;

  constructor() {
    window.addEventListener('resize', this.checkWindowSize.bind(this));
    this.checkWindowSize();
  }

  getMobileable(): boolean {
    return this.mobileable;
  }

  private checkWindowSize(): void {
    this.mobileable = window.innerWidth < 992;
  }
}
