import { Directive, ElementRef, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { Control } from './control';

@Directive({
  selector: 'input[global-ui-input]',
  host: {
    class: 'a3l-ui-input',
    '(blur)': 'focused = false',
    '(focus)': 'focused = true',
    '(onFocus)': 'focused = true',
    '(onBlur)': 'focused = false',
  },
  providers: [{ provide: Control, useExisting: InputDirective }],
})
export class InputDirective extends Control {
  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    return this.focused;
  }

  /**
   * Create a new instance.
   *
   * @param {NgControl} ngControl
   * @param {ElementRef} elementRef
   */
  constructor(@Optional() @Self() public ngControl: NgControl, private elementRef: ElementRef) {
    super();
  }

  /**
   * Focus on the input.
   *
   * @return void
   */
  focusin(): void {
    this.elementRef.nativeElement.focus();
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    this.elementRef.nativeElement.blur();
  }
}
