import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CookieDB } from '@a3l/core';
import { GlobalLanguageSwitcherCommand } from '@rex/shared/global/commands/global-language.switcher.command';
import { LandingLanguageService } from '@rex/landing/services/landing-language.service';

@Component({
  selector: 'global-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['language-switcher.component.scss', '../../../job-board/components/filters/global-sort.component.scss'],
  host: {
    class: 'global-language-switcher',
  },
  encapsulation: ViewEncapsulation.None,
  providers: [
    GlobalLanguageSwitcherCommand
  ]
})
export class LanguageSwitcherComponent implements OnInit, OnDestroy {
  @Input()
  customClass: string = 'py-4 px-2'

  @Input()
  multiLangUrls: boolean = false;

  isOpen = false;

  /**
   * @var {string}
   */
  code: string = this.translator.currentLang;

  readonly sortOptions = [
    {code: 'pl'},
    {code: 'en'},
  ];

  selectedOption = { code: 'pl' };

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  constructor(private translator: TranslateService, private languageService: LandingLanguageService) {
  }

  ngOnInit() {
    this.subscription = this.translator.onLangChange.subscribe(({ lang }) => (this.code = lang));
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  selectOption(selectedOption: { code: string;}) {
    if (this.code == selectedOption.code) {
      this.isOpen = false;
      return;
    }

    this.selectedOption = selectedOption;

    CookieDB.put('language', selectedOption.code);

    if (this.multiLangUrls) {
      this.languageService.switchLanguage(selectedOption.code);
    } else  {
      window.location.reload();
    }
  }


  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
