import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  private intercomLoaded = false;

  constructor(protected  translate: TranslateService) {}

  initIntercom(): void {
    if (!environment.intercom.enabled || this.intercomLoaded) {
      return;
    }

    const allowedDomain = environment.urls.main_domain
    const currentDomain = window.location.hostname;

    if (currentDomain !== allowedDomain) {
      console.warn(`Intercom nie został załadowany – niepoprawna domena: ${currentDomain}`);
      return;
    }

    this.intercomLoaded = true;

    (window as any).intercomSettings = {
      api_base: environment.intercom.apiBase,
      app_id: environment.intercom.appId,
      language_override: this.translate.currentLang
    };

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://widget.intercom.io/widget/${environment.intercom.appId}`;

    script.onload = () => {
      (window as any).Intercom('boot', (window as any).intercomSettings);
    };

    document.head.appendChild(script);
  }

  shutdownIntercom(): void {
    if ((window as any).Intercom) {
      (window as any).Intercom('shutdown');
    }
  }
}
