<section class="mb-8">
  <div class="row pb-6">
    <div class="flex items-center space-x-0 lg:space-x-4">
      <div class="logo-box flex items-center justify-center">
        <img src="{{ offer.logo }}" class="min-h-0 w-full object-contain" alt="Company Logo">
      </div>

      <div class="p-4 flex flex-col items-center justify-center h-full text-center">
        <div class="flex items-start">
          <div class="col-12 p-0 mb-0 lg:mb-4">
            <p class="title text-center">{{ toLocalized(offer.position).getContentForLang(activeLanguage) }}</p>
          </div>
        </div>
        <div class="flex items-end mt-auto" *ngIf="!this.responsiveService.getMobileable()">
          <div class="w-full">
            <div class="flex items-center">
              <ng-container *ngIf="offer.company_name">
                <div class="flex items-center small-attribute mr-6">
                  <global-ui-icon [icon]="'building'" [color]="'gray'"></global-ui-icon>
                  <label class="ml-1.5">{{ offer.company_name }}</label>
                </div>
              </ng-container>
              <ng-container *ngIf="offer.localizations && offer.localizations.length && offer.localizations[0] && offer.localizations[0].name">
                <jb-localizations-display class="mr-6" [localizations]="offer.localizations" [showAsTooltip]="true"></jb-localizations-display>
              </ng-container>
              <ng-container *ngIf="offer.industries && offer.industries.length">
                <jb-tooltip class="mr-6" [icon]="'suitcase'" [data]="offer.industries"></jb-tooltip>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="{{ mainDataClass }}">
    <ng-content></ng-content>
  </div>

  <div class="{{ mainDataClass }}">
    <!-- Skills -->
    <div class="row mt-12 mb-4" *ngIf="offer.skills && offer.skills.length">
      <div class="col-12 p-0">
        <h2 class="subtitle mb-10">{{ 'jobboard::index.required_skills' | translate }}</h2>
      </div>
      <ng-container *ngIf="offer.skills">
        <div *ngFor="let skill of offer.skills| slice:0:displaySkillsCount" class="p-0 col-6 lg:col-3 gap-4">
          <jb-skill-stars [skill]="skill"></jb-skill-stars>
        </div>
      </ng-container>
      <a  class="cursor-pointer show_more" *ngIf="displaySkillsCount < offer.skills.length" (click)="showMoreSkills()">
        {{ 'jobboard::index.show_all_skills' | translate }} {{ '(+' + this.showMoreSkillsItemsCount() + ')' }}
      </a>
    </div>

    <!-- Languages -->
    <div class="row mb-4 mt-12" *ngIf="offer.languages && offer.languages.length">
      <div class="col-12 p-0">
        <h2 class="subtitle mb-10">{{ 'jobboard::index.required_languages' | translate }}</h2>
      </div>
      <div *ngFor="let language of offer.languages" class="p-0 col-6 lg:col-3">
        <jb-language-skill [language]="language"></jb-language-skill>
      </div>
    </div>

    <!-- Offer -->
    <div class="row mb-12" *ngIf="offer.company_offer_and_benefits || offer.short_job_offer">
      <div class="col-12 p-0">
        <h2 class="subtitle mb-8">{{ 'jobboard::index.offer_description' | translate }}</h2>
      </div>
      <div class="col-12 p-0 description-intro mb-4" *ngIf="offer.short_job_offer && offer.short_job_offer.length" [innerHTML]="toLocalized(offer.short_job_offer).getContentForLang(activeLanguage)"></div>
      <div class="col-12 p-0 description" *ngIf="offer.company_offer_and_benefits && offer.company_offer_and_benefits.length" [innerHTML]="toLocalized(offer.company_offer_and_benefits).getContentForLang(activeLanguage)"></div>
    </div>

    <div class="row mb-12" *ngIf="showAdsBanners">
      <div class="col-12 p-0">
        <jb-banner [buttonText]="'jobboard::index.candidate_banner.title'" [buttonLink]="'/client-registration'">
          <div class="flex items-center space-x-4">
            <global-ui-icon [icon]="'profile'" [color]="'white'" class="jb-banner-title"></global-ui-icon>
            <h1 class="jb-banner-title">{{ 'jobboard::index.candidate_banner.title' | translate }}</h1>
          </div>

          <ul class="mt-4 ml-4 text jb-banner-content">
            <li><global-ui-icon [icon]="'check'" [color]="'white'"></global-ui-icon> {{ 'jobboard::index.candidate_banner.option_1' | translate }}</li>
            <li><global-ui-icon [icon]="'check'" [color]="'white'"></global-ui-icon> {{ 'jobboard::index.candidate_banner.option_2' | translate }}</li>
            <li><global-ui-icon [icon]="'check'" [color]="'white'"></global-ui-icon> {{ 'jobboard::index.candidate_banner.option_3' | translate }}</li>
            <li><global-ui-icon [icon]="'check'" [color]="'white'"></global-ui-icon> {{ 'jobboard::index.candidate_banner.option_4' | translate }}</li>
          </ul>
        </jb-banner>
      </div>
    </div>

    <!-- Responsibilities -->
    <div class="row mb-12" *ngIf="offer.responsibilities">
      <div class="col-12 p-0">
        <h2 class="subtitle mb-6">{{ 'jobboard::index.responsibilities' | translate }}</h2>
      </div>
      <div class="col-12 p-0 description-black" [innerHTML]="toLocalized(offer.responsibilities).getContentForLang(activeLanguage)"></div>
    </div>

    <!-- Desired experience -->
    <div class="row mb-12" *ngIf="offer.desired_experience">
      <div class="col-12 p-0">
        <h2 class="subtitle mb-6">{{ 'jobboard::index.desired_experience' | translate }}</h2>
      </div>
      <div class="col-12 p-0 description-black" [innerHTML]="toLocalized(offer.desired_experience).getContentForLang(activeLanguage)"></div>
    </div>
  </div>
</section>
