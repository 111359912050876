import { Component, Input, ViewChild } from '@angular/core';
import { GoogleMapComponent } from '@rex/job-board/components/google-map.component';
import { GOOGLE_MAPS_OVERLAY_STYLE, GOOGLE_MAPS_CENTER } from '@rex/job-board/components/google-map.config';
import { Offer } from '@rex/job-board/models/offer.model';

@Component({
  selector: 'jb-localizations-presenter',
  templateUrl: './localizations.presenter.html',
  styleUrls: ['localizations.presenter.scss']
})
export class LocalizationsPresenter {
  /**
   * @var {any}
   */
  @Input()
  value: any;


  @Input()
  offer: Offer

  /**
   * @var {boolean}
   */
  loading: boolean = true;

  /**
   * @var {any}
   */
  overlay: any = null;

  /**
   * @var {GoogleMapComponent}
   */
  @ViewChild(GoogleMapComponent, { static: false })
  googleMap: GoogleMapComponent;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Initialization.
   *
   * @return void
   */
  init(): void {
    const google = (window as any).google;
    let markers = [];

    let zoom = 6;

    const firstLocation = this.offer.localizations[0];

    this.googleMap.map.setZoom(zoom);
    this.googleMap.map.setCenter(new google.maps.LatLng(firstLocation.lat, firstLocation.lng));

    this.offer.localizations.forEach((location, index) => {
      if (index === 0) {
        const marker = new google.maps.Marker({
          position: { lat: location.lat, lng: location.lng },
          map: this.googleMap.map,
          icon: {
            url: 'assets/job-board/icons/black-marker.svg',
            scaledSize: new google.maps.Size(40, 40),
          },
        });

        markers.push(marker);
      } else {
        const marker = new google.maps.Marker({
          position: { lat: location.lat, lng: location.lng },
          map: this.googleMap.map,
          icon: {
            url: 'assets/job-board/icons/white-marker.svg',
            scaledSize: new google.maps.Size(30, 30),
          },
        });

        markers.push(marker);
      }
    });


    this.loading = false;
  }
}
