import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'a[global-button], button[global-button]',
  templateUrl: 'button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'global-button',
    '[class]': "'global-button--' + color",
  },
})
export class ButtonComponent {
  @Input()
  color: 'default' | 'black' | 'yellow' | 'black-border' | 'transparent-gray' | 'gray' |  'gray-border' | 'black-revert' | 'transparent-black' | 'paginator' | 'grayblack' | 'white' | 'white-black' = 'default';

  /**
   * @var {boolean}
   */
  @Input()
  processing: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
