import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'global-badge',
  template: '<ng-content></ng-content>',
  styleUrls: ['badge.component.scss'],
  host: {
    class: 'global-badge',
    '[style.cursor]': 'cursor',
    '[class]': "'global-badge--' + color",
    '[class.cursor-pointer]': "pointer === true",
  },
  encapsulation: ViewEncapsulation.None,
})
export class BadgeComponent {

  /**
   * @var {string}
   */
  @Input()
  color: 'default' | 'gray' = 'default';

  @Input()
  pointer: boolean = false;

  @Input()
  cursor: 'pointer' = null;
}
