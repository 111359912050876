import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@rex/shared/global/src/lib/core/icon/icon.component';

const PUBLIC_API = [
];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API]
})

export class GlobalIconModule {

}
