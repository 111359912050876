import { Component, Input } from '@angular/core';

@Component({
  selector: 'global-apply-button',
  templateUrl: 'apply-button.component.html',
  styleUrls: ['./apply-button.component.scss'],
  host: {
    class: 'apply-button',
    '[class]': "'apply-button--' + color",
  },
})
export class ApplyButtonComponent {
  @Input()
  customClass: string;

  /**
   * @var {boolean}
   */
  @Input()
  processing: boolean = false;

  @Input()
  href: string;

  @Input()
  text: string = 'jobboard::index.apply_button';

  @Input()
  color: 'default' | 'blue' | 'black' | 'gray-light' | 'yellow-black' = 'default';

  apply() {
    if (this.href) {
      window.open(this.href, '_blank');
    }
  }
}
