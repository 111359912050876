import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';

import { DropdownComponent } from './dropdown.component';

import { DropdownDirective } from './dropdown.directive';
import { DropdownPositionOriginDirective } from './dropdown-position-origin.directive';

const PUBLIC_API = [DropdownComponent, DropdownDirective, DropdownPositionOriginDirective];

@NgModule({
    declarations: [...PUBLIC_API],
    imports: [CommonModule, PortalModule, OverlayModule],
    exports: [...PUBLIC_API]
})
export class GlobalDropdownModule {}
