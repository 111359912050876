import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize} from 'rxjs/operators';
import {Command} from '@a3l/core';
import { ApiService } from '@a3l/api';

@Injectable({ providedIn: 'root' })
export class GlobalLanguageSwitcherCommand extends Command<any> {
  /**
   * Create a new instance.
   *
   * @param {ApiService} api
   */
  constructor(private api: ApiService) {
    super();
  }

  /**
   * Run the command.
   *
   * @param {string} language
   * @return Observable<string>
   */
  run(language: any): Observable<any> {
    const formData = new FormData();

    formData.append('language', language);

    return this.api.post(`/me`, formData).pipe(finalize(() => this._pending$.next(false)));
  }

  /**
   * Run the command.
   *
   * @param {string} language
   * @return void
   */
  execute(language: string): void {
    this.run(language).subscribe(() => {
      this._complete$.next();

      window.location.reload();
    });
  }
}
