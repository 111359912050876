import { NgModule } from '@angular/core';
import { InputDirective } from '@rex/shared/global/src/lib/form/input.directive';
import { GroupComponent } from '@rex/shared/global/src/lib/form/group.component';
import { CommonModule } from '@angular/common';
import { CurrencyInputComponent } from '@rex/shared/global/src/lib/form/currency-input.component';
import { TranslateModule } from '@ngx-translate/core';

const PUBLIC_API = [
  InputDirective,
  GroupComponent,
  CurrencyInputComponent
]

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule, TranslateModule],
  exports: [...PUBLIC_API],
})
export class GlobalFormModule {}
