import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'global-logo',
  templateUrl: 'logo.component.html',
  styleUrls: ['logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogoComponent {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
