<a3l-layout>
  <section class="flex flex-col md:flex-row flex-1">
    <main class="flex-grow flex flex-col">
      <nav class="p-6 items-center md:p-12 nav-box" [ngClass]="this.slug == 'signin' || this.path == 'password'  ? 'flex full-container w-full' : 'row'">
        <div class="col-6 flex items-center justify-between">
          <a routerLink="/" class="mr-8" *ngIf="!this.goToLanding">
            <rex-logo></rex-logo>
          </a>
          <a [href]="getLandingUrl()" class="mr-8" *ngIf="this.goToLanding">
            <rex-logo></rex-logo>
          </a>
        </div>
        <!-- Start of: CTA for screen size < MD -->
        <div  class="col-6" *ngIf="mobileable" >
          <router-outlet name="cta"></router-outlet>
        </div>
        <!-- End of: CTA for screen size < MD -->
        <div class="col-6 text-right text-xs" *ngIf="!mobileable">
          <router-outlet name="cta"></router-outlet>

          <div class="flex items-center justify-end" *ngIf="'' | rexAuthuser | async as authuser">
            <ng-container *rex-auth-role="['superadmin', 'leader']">
              <span>{{ authuser.first_name + ' ' + authuser.last_name }}</span>
            </ng-container>

            <ng-container *rex-auth-role="'recruiter'">
              <ng-container *ngIf="showUserDropdown">
                <div class="hidden sm:flexic cursor-pointer" [a3l-ui-dropdown]="usermenu">
                  <rex-avatar small [src]="authuser.avatar"></rex-avatar>
                  <div class="mx-2 leading-none">
                    <strong class="block font-semibold text-sm">
                      <ng-container *ngIf="hasParentAuthUser">
                        {{ 'Działasz jako' | translate }}
                      </ng-container>
                      {{ authuser.first_name + ' ' + authuser.last_name }}
                    </strong>
                    <small class="font-medium text-xxs text-gray">{{ authuser.organization.name | a3lTruncate:20 }}</small>
                  </div>
                  <a3l-ui-icon class="bg-gray-light text-gray" icon="chevron-down" style="font-size: 5px"></a3l-ui-icon>
                </div>
              </ng-container>
              <ng-container *ngIf="!showUserDropdown">
                <div class="sm:flexic">
                  <rex-avatar small [src]="authuser.avatar"></rex-avatar>
                  <div class="mx-2 leading-none">
                    <strong class="block font-semibold text-sm">
                      <ng-container *ngIf="hasParentAuthUser">
                        {{ 'Działasz jako' | translate }}
                      </ng-container>
                      {{ authuser.first_name + ' ' + authuser.last_name }}
                    </strong>
                    <small class="font-medium text-xxs text-gray">{{ authuser.organization.name | a3lTruncate:20 }}</small>
                  </div>
                </div>
              </ng-container>
              <a3l-ui-dropdown #usermenu>
                <div class="flexic p-2">
                  <rex-avatar small [src]="authuser.avatar"></rex-avatar>
                  <div class="mx-2 leading-none">
                    <strong class="block font-semibold text-sm">
                      <ng-container *ngIf="hasParentAuthUser">
                        {{ 'Działasz jako' | translate }}
                      </ng-container>
                      {{ authuser.first_name + ' ' + authuser.last_name }}
                    </strong>
                    <small class="font-medium text-xxs text-gray">{{ authuser.organization.name | a3lTruncate:20 }}</small>
                  </div>
                  <a3l-ui-icon class="bg-gray-light text-gray" icon="chevron-up" style="font-size: 5px"></a3l-ui-icon>
                </div>
                <ul class="m-0 p-0 pt-2 border-t-3 border-solid border-gray-light list-none text-md font-medium">
                  <li class="py-2 px-3 text-sm" translate>Organizacje</li>
                  <ng-container *ngFor="let organization of authuser.organizations">
                    <li class="p-3 flex items-center justify-between border-b-2 border-solid border-gray-light" *ngIf="organization.type != 'temporary'">
                      <a
                        class="as-link no-underline opacity-50"
                        [routerLink]="['/organization/switch', organization.id]"
                        [ngClass]="{'opacity-100 font-semibold pointer-events-none': organization.id == authuser.organization.id}"
                      >
                        <span *ngIf="organization.name; else noname">{{ organization.name | a3lTruncate:18 }}</span>
                        <ng-template #noname>{{ 'Organizacja bez nazwy' | translate | a3lTruncate:18 }}</ng-template>
                      </a>
                      <a3l-ui-icon icon="check" class="text-xxs" *ngIf="organization.id == authuser.organization.id"></a3l-ui-icon>
                    </li>
                  </ng-container>
                  <ng-container *rex-auth-status="[recruiterStatus.ACTIVE, recruiterStatus.INACTIVE, recruiterStatus.WAITING, recruiterStatus.UNKNOWN]">
                    <li class="p-3">
                      <button type="button" routerLink="/organization/add" class="w-full" a3l-ui-button small translate>Dodaj organizację</button>
                    </li>
                  </ng-container>
                </ul>
              </a3l-ui-dropdown>


            </ng-container>

            <a routerLink="/logout" class="ml-5 font-medium" a3l-ui-button small translate>Wyloguj się</a>
          </div>
        </div>
      </nav>
      <a3l-layout-main id="main">
        <router-outlet></router-outlet>
      </a3l-layout-main>

      <!-- Start of: Footer for screen size >= MD -->
      <a3l-layout-footer class="hidden px-6 text-gray text-xs md:px-12 md:block">
        <div class="row">
          <div class="col-6">
            <a3l-layout-copyrights><span translate>Copyright by REX by Antal. Wszelkie prawa zastrzeżone.</span></a3l-layout-copyrights>
          </div>
          <div class="col-6">
            <ul class="m-0 -mx-5 p-0 list-none flex justify-end text-sm font-medium">
              <li class="mx-5">
                <a routerLink="/privacy-policy" target="_blank" translate>Polityka prywatności</a>
              </li>
              <li class="mx-5">
                <a routerLink="/terms" target="_blank" translate>Regulamin</a>
              </li>
              <li class="mx-5" *rex-auth-logged-in>
                <a translate rex-tutorial-open>Szkolenie</a>
              </li>
            </ul>
          </div>
        </div>
      </a3l-layout-footer>
      <!-- End of: Footer for screen size >= MD -->
    </main>

    <aside id="aside" [hidden]="!aside.isActivated" [class]="'aside aside--' + this.slug">
      <router-outlet name="aside" #aside="outlet"></router-outlet>
    </aside>

    <!-- Start of: Footer for screen size < MD -->
    <a3l-layout-footer class="p-10 text-gray text-center md:hidden">
      <ul class="m-0 -mx-5 p-0 list-none flex justify-center text-sm font-medium">
        <li class="mx-5">
          <a routerLink="/privacy-policy" target="_blank" translate>Polityka prywatności</a>
        </li>
        <li class="mx-5">
          <a routerLink="/terms" target="_blank" translate>Regulamin</a>
        </li>
        <li class="mx-5" *rex-auth-logged-in>
          <a translate rex-tutorial-open>Szkolenie</a>
        </li>
      </ul>
      <a3l-layout-copyrights class="mt-10 block text-xs"><span translate>Copyright by REX by Antal. Wszelkie prawa zastrzeżone.</span></a3l-layout-copyrights>
    </a3l-layout-footer>
    <!-- End of: Footer for screen size < MD -->
  </section>
</a3l-layout>
