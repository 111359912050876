import { Specification } from '@a3l/core';
import {OrganizationUserStatusEnum} from "@rex/organization/enums/organization-user-status.enum";

export class CandidateNeedsProfileUpdateSpecification extends Specification<any> {

  isSatisfiedBy(candidate: any): boolean {
    if (candidate.candidate_should_fill_profile ) {
      return true;
    }
    return false;
  }
}
