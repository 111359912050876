<div class="rate-component mb-10">
  <p class="title mb-3 flex items-center">
    <global-flag large [code]="language.key" class="mr-2"></global-flag>
    {{ language.value }}
  </p>
  <p class="space-x-1 mb-0">
    <span *ngFor="let star of starMax">
      <global-ui-icon [icon]="'star-filled'" [color]="getStarColor(star, language)"></global-ui-icon>
    </span>
  </p>
  <p class="level-text mt-0.5">
    {{ language.attributes.level == 'native' ? ('jobboard::index.native' | translate) : language.attributes.level }}
  </p>
</div>
