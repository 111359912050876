<div class="relative w-full bg-cover jb-banner-background overflow-hidden" [ngStyle]="{'background-image': getBackgroundImage()}">
  <div class="jb-banner-gradient absolute inset-0 w-full h-full"></div>

  <div class="relative z-10 flex justify-between h-full text-white">
    <div class="w-1/2">
      <ng-content></ng-content>
    </div>

    <div class="w-1/2 flex items-center justify-end">
      <global-apply-button (click)="redirectTo()" [text]="buttonText | translate" [color]="color"></global-apply-button>
    </div>
  </div>
</div>
