import { Component, ViewEncapsulation, TemplateRef, ViewChild, ContentChild, ElementRef, Renderer2 } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subject } from 'rxjs';

import { DropdownPositionOriginDirective } from './dropdown-position-origin.directive';

@Component({
  selector: 'global-ui-dropdown',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [trigger('visibility', [state('void', style({ opacity: 0 })), state('enter', style({ opacity: 1 })), transition('* => void', animate('100ms 25ms linear', style({ opacity: 0 })))])],
})
export class DropdownComponent {
  /**
   * @var {ElementRef}
   */
  @ViewChild('panel', { static: false })
  panel: ElementRef;

  /**
   * @var {TemplateRef<any>}
   */
  @ViewChild(TemplateRef)
  templateRef: TemplateRef<any>;

  /**
   * @var {ElementRef}
   */
  @ContentChild(DropdownPositionOriginDirective)
  positionOrigin: DropdownPositionOriginDirective;

  /**
   * @var {'void' | 'enter'}
   */
  state: 'void' | 'enter' = 'void';

  /**
   * @var {Subject<any>}
   */
  protected afterDestroy = new Subject<any>();

  /**
   * Create a new instance.
   *
   * @param {Renderer2} renderer
   */
  constructor(private renderer: Renderer2) {
    //
  }

  /**
   * Show the dropdown.
   *
   * @param {ElementRef} element
   * @return void
   */
  show(element: ElementRef): void {
    this.state = 'enter';

    // next tick
    setTimeout(() => this.renderer.setStyle(this.panel.nativeElement, 'minWidth', `15rem`));
  }

  /**
   * Hide the dropdown.
   *
   * @return void
   */
  hide(): void {
    this.state = 'void';
  }

  /**
   * Destroy the dropdown.
   *
   * @return void
   */
  destroy(): void {
    this.afterDestroy.next();
  }

  /**
   * Gets an observable that is notified when the dropdown is destroyed.
   *
   * @return Observable
   */
  afterDestroyed() {
    return this.afterDestroy.asObservable();
  }
}
