import { Component, OnInit } from '@angular/core';
import {ListenActionForEvaluationService} from "@rex/evaluations/services/listen-action-for-evaluation.service";
import { IntercomService } from '@rex/services/intercom.service';
import { OnIdentifyEffects } from '@ngrx/effects';
import { UpdateService } from '@rex/UpdateService';

@Component({
  selector: 'rex-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  /**
   * Create a new instance.
   */
  constructor(
    private swUpdate: UpdateService,
    private listenActionForEvaluationService: ListenActionForEvaluationService,
    private intercomService: IntercomService
  ) {
    this.swUpdate.checkForUpdates();
    this.listenActionForEvaluationService.runEvaluationRequestActions();
  }

  ngOnInit() {
    this.intercomService.initIntercom();
  }
}

