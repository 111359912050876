import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlagComponent } from './flag.component';

const PUBLIC_API = [FlagComponent];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API],
})
export class GlobalFlagModule {}
