<div class="flex items-center">
  <img class="global-searchbar__icon" src="../../../../../../../assets/job-board/icons/search.svg" alt="Serch icon"/>

  <div class="flex-grow">
    <label for="position-input" class="block global-searchbar__label mb-1">{{ this.label | translate }}</label>
    <input
      type="text"
      id="position-input"
      placeholder="{{ this.placeholder | translate }}"
      class="global-searchbar__input"
      [disabled]="isDisabled"
      [value]="value"
      (input)="onInputChange($event.target.value)"
    />
  </div>
</div>

