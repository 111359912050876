import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SkillModel } from '@rex/job-board/models/skill.model';

@Component({
  selector: 'jb-skill-stars',
  templateUrl: 'skills-stars.component.html',
  styleUrls: ['skills-stars.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SkillsStarsComponent {
  starMax = Array(5).fill(0).map((_, i) => i + 1);

  @Input()
  skill: SkillModel

  getStarColor(starIndex: number, skill: SkillModel) {
    if(starIndex <= skill.level) {
      return 'yellow';
    }

    return 'gray-light';
  }

  getLevelText(level: number): string|null
  {
    switch (level) {
      case 1:
        return 'Intern';
      case 2:
        return 'Junior'
      case 3:
        return 'Regular'
      case 4:
        return 'Master'
      case 5:
        return 'Guru'
      default:
        return null;
    }
  }
}
