import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@rex/environments/environment';

@Component({
  selector: 'jb-banner',
  templateUrl: 'banner.component.html',
  styleUrls: ['banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'jb-banner',
    '[class]': "'jb-banner--' + color"
  }
})
export class BannerComponent {
  @Input()
  color: 'blue' | 'black' | 'gray-light' = 'blue';

  @Input()
  banner: 'register_banner' | 'manual_banner' | 'office_work' = 'register_banner';

  @Input()
  buttonText: string;

  @Input()
  buttonLink: string;

  getBackgroundImage() {
    return 'url(assets/job-board/img/' + this.banner +'.jpg)';
  }
  /**
   * Create a new instance.
   */
  constructor(private router: Router) {
    //
  }

  redirectTo()
  {
    const url = this.router.serializeUrl(this.router.createUrlTree([this.buttonLink]));
    window.open(environment.urls.rex + url, '_blank');
  }
}
