// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  maintenance: false,
  xdebug: false,
  app: {
    name: 'REX – Recruitment Exchange',
    language: 'pl',
  },
  api: {
    assets: 'https://develop.recruitmentexchange.dev/api/storage',
    endpoint: 'https://develop.recruitmentexchange.dev/api/v0',
    cms: 'https://blog-api.rex.hr/api/v1',
  },
  services: {
    google: {
      maps: {
        key: 'AIzaSyDVg4gTiLhSN5LdfJM8196czILgmbB7qMI',
        language: 'pl',
      },
    },
    pusher: {
      key: '332118ff5841bd9e467e',
      cluster: 'eu',
      encryption_key: '4EJeb4IHarWoc3ATbU2Pb4sq7J3PnGuBGtJQ7fUIu20=',
    }
  },
  urls: {
    rex: 'https://develop.app.recruitmentexchange.dev',
    job: 'https://develop.job.recruitmentexchange.dev',
    lp: 'https://develop.recruitmentexchange.dev',
    main_domain: 'rex.hr',
  },
  intercom: {
    enabled: true,
    appId: "yblyca29",
    apiBase: "https://api-iam.intercom.io",
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
