<div class="rate-component mb-10">
  <p class="title mb-3 pr-4">{{ skill.value }}</p>
  <p class="space-x-1 mb-0">
    <span *ngFor="let star of starMax">
      <global-ui-icon [icon]="'star-filled'" [color]="getStarColor(star, skill)"></global-ui-icon>
    </span>
  </p>
  <p class="level-text mt-0.5" *ngIf="getLevelText(skill.level)">
    {{ getLevelText(skill.level) | translate }}
  </p>
</div>
