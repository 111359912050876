<section>
  <footer class="content" *ngIf="!this.responsiveService.getMobileable()">
    <div class="purple-footer"></div>
    <div class="blue-footer"></div>

    <div class="container mx-auto pt-20 pb-10">
      <div class="flex flex-col items-start">
        <div class="row w-full mb-9">
          <rex-logo></rex-logo>
        </div>

        <div class="row w-full justify-between">
          <div class="main-data">
            <p class="description">
              {{ 'landing.navbar.footer_label' | translate }}
            </p>
            <div class="contact-details">
              <div class="basic">
                <a class="phone" href="tel:+48788123123"><global-ui-icon [icon]="'phone'" [size]="1.125"></global-ui-icon>+48 788 123 123</a>
                <a class="mail" href="mailto:hello@rex.hr"><global-ui-icon [icon]="'mail'" [size]="1.125" color="gray-new"></global-ui-icon>hello@rex.hr</a>
                <div class="address"><global-ui-icon [icon]="'marker'" [size]="1" color="gray-new"></global-ui-icon>Puławska 2<br>02-566 {{ 'Warszawa' | translate }}}</div>
              </div>
              <div class="socials">
                <a href="https://www.linkedin.com/company/rex-recruitment-exchange/" target="_blank">
                  <div class="element">
                    <global-ui-icon [icon]="'linked'" [size]="0.875" [color]="'gray-new'"></global-ui-icon>
                  </div>
                </a>
                <a href="https://www.facebook.com/ReXRecruitmentExchange" target="_blank">
                  <div class="element">
                    <global-ui-icon [icon]="'facebook'" [size]="0.475" [color]="'gray-new'"></global-ui-icon>
                  </div>
                </a>
                <a href="https://www.youtube.com/@rexrecruitmentexchange5534" target="_blank">
                  <div class="element">
                    <global-ui-icon [icon]="'you-tube'" [size]="0.875" [color]="'gray-new'"></global-ui-icon>
                  </div>
                </a>
                <a href="https://x.com/ExchangeRex" target="_blank">
                  <div class="element">
                    <global-ui-icon [icon]="'twitter'" [size]="0.675" [color]="'gray-new'"></global-ui-icon>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="links">
            <div class="links-block">
              <div class="header">
                ReX
              </div>
              <div class="urls">
                <p (click)="navigate('about')">{{ 'landing.navbar.about_us' | translate }}</p>
                <p (click)="navigate('blog')">{{ 'landing.navbar.knowledge' | translate }}</p>
                <p (click)="navigate('contact-us')">{{ 'landing.navbar.help' | translate }}</p>
                <a href="{{ this.getSignInUrl() }}" target="_blank"><p>{{ 'landing.navbar.sign_in' | translate }}</p></a>
              </div>
            </div>
            <div class="links-block">
              <div class="header">
                {{ 'landing.navbar.employer' | translate }}
              </div>
              <div class="urls">
                <p (click)="navigate('employer', 'benefits')">{{ 'landing.navbar.employer_navbar.benefits' | translate}}</p>
                <p (click)="navigate('employer', 'recruitment-process')">{{ 'landing.navbar.employer_navbar.recruitment' | translate}}</p>
                <p (click)="navigate('employer', 'solutions')">{{ 'landing.navbar.employer_navbar.solutions' | translate}}</p>
              </div>
            </div>
            <div class="links-block">
              <div class="header">
                {{ 'landing.navbar.headhunter' | translate }}
              </div>
              <div class="urls">
                <p (click)="navigate('headhunter', 'benefits')">{{ 'landing.navbar.headhunter_navbar.benefits' | translate}}</p>
                <p (click)="navigate('headhunter', 'recruitment')">{{ 'landing.navbar.headhunter_navbar.recruitment' | translate}}</p>
              </div>
            </div>
            <div class="links-block">
              <div class="header">
                {{ 'landing.navbar.candidate' | translate }}
              </div>
              <div class="urls">
                <p (click)="navigate('candidate', 'information-section')">{{ 'landing.navbar.candidate_navbar.benefits' | translate}}</p>
                <p (click)="navigate('candidate', 'job-offers')">{{ 'landing.navbar.candidate_navbar.job' | translate}}</p>
                <p (click)="navigate('candidate', 'exchange')">{{ 'landing.navbar.candidate_navbar.exchange' | translate}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="copyrights mt-20">
          <p>2025 © Copyright Antal Sp. z o.o. Wszystkie prawa zastrzeżone. Polityka prywatoności</p>
        </div>
      </div>
    </div>
  </footer>

  <footer class="content" *ngIf="this.responsiveService.getMobileable()">
    <div class="main-data">
      <rex-logo></rex-logo>
      <p class="description">
        {{ 'Nowoczesna platforma, dzięki której znajdziesz najlepszych kandydatów!' | translate }}
      </p>
      <div class="contact-details">
        <div class="basic">
          <a class="phone" href="tel:+48788123123"><global-ui-icon [icon]="'phone'" [size]="1.125"></global-ui-icon>+48 788 123 123</a>
          <a class="mail" href="mailto:hello@rex.hr"><global-ui-icon [icon]="'mail'" [size]="1.125" color="gray-new"></global-ui-icon>hello@rex.hr</a>
          <div class="address"><global-ui-icon [icon]="'marker'" [size]="1" color="gray-new"></global-ui-icon>Puławska 2<br>02-566 {{ 'Warszawa' | translate}}} </div>
        </div>
        <div class="socials">
          <div class="element">
            <global-ui-icon [icon]="'linked'" [size]="0.875" [color]="'gray-new'"></global-ui-icon>
          </div>
          <div class="element">
            <global-ui-icon [icon]="'facebook'" [size]="0.475" [color]="'gray-new'"></global-ui-icon>
          </div>
          <div class="element">
            <global-ui-icon [icon]="'you-tube'" [size]="0.875" [color]="'gray-new'"></global-ui-icon>
          </div>
          <div class="element">
            <global-ui-icon [icon]="'twitter'" [size]="0.675" [color]="'gray-new'"></global-ui-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="links">
      <div class="links-block">
        <div class="header">
          ReX
        </div>
        <div class="urls">
          <p (click)="navigate('about')">{{ 'landing.navbar.about_us' | translate }}</p>
          <p (click)="navigate('blog')">{{ 'landing.navbar.knowledge' | translate }}</p>
          <p (click)="navigate('contact-us')">{{ 'landing.navbar.help' | translate }}</p>
          <a href="{{ this.getSignInUrl() }}" target="_blank"><p>Zaloguj się</p></a>
        </div>
      </div>
      <div class="links-block">
        <div class="header">
          {{ 'landing.navbar.employer' | translate }}
        </div>
        <div class="urls">
          <p (click)="navigate('employer', 'benefits')">{{ 'landing.navbar.employer_navbar.benefits' | translate}}</p>
          <p (click)="navigate('employer', 'recruitment-process')">{{ 'landing.navbar.employer_navbar.recruitment' | translate}}</p>
          <p (click)="navigate('employer', 'solutions')">{{ 'landing.navbar.employer_navbar.solutions' | translate}}</p>
        </div>
      </div>
      <div class="links-block">
        <div class="header">
          {{ 'landing.navbar.headhunter' | translate }}
        </div>
        <div class="urls">
          <p (click)="navigate('headhunter', 'benefits')">{{ 'landing.navbar.headhunter_navbar.benefits' | translate}}</p>
          <p (click)="navigate('headhunter', 'recruitment')">{{ 'landing.navbar.headhunter_navbar.recruitment' | translate}}</p>
        </div>
      </div>
      <div class="links-block">
        <div class="header">
          {{ 'landing.navbar.candidate' | translate }}
        </div>
        <div class="urls">
          <p (click)="navigate('candidate', 'information-section')">{{ 'landing.navbar.candidate_navbar.benefits' | translate}}</p>
          <p (click)="navigate('candidate', 'job-offers')">{{ 'landing.navbar.candidate_navbar.job' | translate}}</p>
          <p (click)="navigate('candidate', 'exchange')">{{ 'landing.navbar.candidate_navbar.exchange' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="copyrights mt-8">
      <div>2025 © Copyright Antal Sp. z o.o. <br> Wszystkie prawa zastrzeżone. Polityka prywatoności</div>
    </div>

  </footer>
</section>
