<ng-container *ngIf="data.length && data.length > 1; else singleItem">
  <div class="flex items-center small-attribute" [pTooltip]="getDataString()" tooltipPosition="bottom" tooltipStyleClass="jb-custom-tooltip">
    <global-ui-icon [icon]="icon" [color]="color"></global-ui-icon>
    <label class="mx-1.5 cursor-pointer">{{data[0] | translate }}, + {{data.length - 1}}</label>
    <global-ui-icon class="icon" [icon]="'chevron-down'" [size]="0.416" [color]="color"></global-ui-icon>
</div>
</ng-container>
<ng-template #singleItem>
  <div class="flex items-center small-attribute">
    <global-ui-icon [icon]="icon" [color]="color"></global-ui-icon>
    <label *ngIf="data.length === 1" class="ml-1.5 cursor-pointer">{{data[0] | translate }}</label>
  </div>
</ng-template>
