import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@rex/shared/global/src/lib/core/button/button.component';
import { ApplyButtonComponent } from '@rex/shared/global/src/lib/core/button/apply-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@a3l/utilities';

const PUBLIC_API = [ButtonComponent, ApplyButtonComponent];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule, TranslateModule, ButtonModule],
  exports: [...PUBLIC_API],
})
export class GlobalButtonModule {}
