import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CookieDB} from "@a3l/core";
import {RecruiterStatus} from "@rex/core/recruiter/enums/status";
import {AuthuserQuery} from "@rex/auth";
import { environment } from '@rex/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  templateUrl: './simple.layout.html',
  styleUrls: ['./simple.layout.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SimpleLayout implements OnInit {
  goToLanding: boolean = false;
  hasParentAuthUser: boolean = false;
  slug: string;
  path: string;

  public recruiterStatus: typeof RecruiterStatus = RecruiterStatus;

  /**
   * @var {boolean}
   */
  get mobileable() {
    return window.innerWidth < 992;
  }

  /**
   * Create a new instance.
   */
  constructor(private query: AuthuserQuery, private route: ActivatedRoute, private location: Location) {

  }

  statusesAllowedToAddOrganization = [this.recruiterStatus.ACTIVE, this.recruiterStatus.INACTIVE, this.recruiterStatus.WAITING, this.recruiterStatus.UNKNOWN]

  showUserDropdown = false;

  ngOnInit() {
    this.query.value$.subscribe((user) => {
      if (user && user.organizations) {
        this.showUserDropdown = user.organizations.length > 1 ?? this.statusesAllowedToAddOrganization.indexOf(user.status) != -1;
      }
    });

    this.route.firstChild?.data.subscribe(data => {
      this.goToLanding = data.goToLanding;
    });

    const currentPath = this.location.path();
    this.slug = currentPath.split('/').pop().split('?')[0];
    this.path = currentPath.split('/')[1];
  }

  /**
   * Initialization.
   */
  ngAfterContentInit() {
    this.hasParentAuthUser = CookieDB.get('parent_access_token');
  }

  getLandingUrl(): string {
    return environment.urls.lp;
  }
}
