import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'global-ui-icon',
  templateUrl: 'icon.component.html',
  styleUrls: ['icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class]': "'global-ui-icon global-ui-icon-' + icon",
    '[style.fontSize.rem]': 'size',
    '[style.width]': 'width',
    '[style.height.rem]': 'height',
    '[class.global-ui-icon--gray]': "color == 'gray'",
    '[class.global-ui-icon--yellow]': "color == 'yellow'",
    '[class.global-ui-icon--white]': "color == 'white'",
    '[class.global-ui-icon--gray-light]': "color == 'gray-light'",
    '[class.global-ui-icon--gray-new]': "color == 'gray-new'",
    '[class.global-ui-icon--black]': "color == 'black'",
    '[class.global-ui-icon--primary]': "color == 'primary'",
    '[class.global-ui-icon--tertiary]': "color == 'tertiary'",
    '[class.global-ui-icon--secondary]': "color == 'secondary'",
    '[class.global-ui-icon--secondary-v2]': "color == 'secondary-v2'",

  }
})
export class IconComponent {
  /**
   * @var {string}
   */
  @Input()
  icon: string;

  /**
   * @var {string}
   */
  @Input()
  width: string;

  /**
   * @var {string}
   */
  @Input()
  height: string;

  /**
   * @var {number}
   */
  @Input()
  size: number;

  @Input() color: 'gray'|'yellow'|'gray-light'|'white'|'black'|'gray-new'|'primary'|'tertiary'|'secondary'|'secondary-v2';

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
