import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeComponent } from '@rex/shared/global/src/lib/core/badge/badge.component';

const PUBLIC_API = [BadgeComponent];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API],
})
export class GlobalBadgeModule {

}
