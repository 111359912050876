import { Specification } from '@a3l/core';
import {RoleInOrganizationEnum} from "@rex/organization/enums/role-in-organization.enum";

export class HasActivatedAccountSpecification extends Specification<any> {
  roleInOrganizationEnum: typeof RoleInOrganizationEnum = RoleInOrganizationEnum;

  isSatisfiedBy(user: any): boolean {
    const { role, status, agreement, profile } = user;

    if (role == 'superadmin') return true;

    if (role == 'leader') {
      const { industries, languages, localization, experience_in_years: experienceInYears, phone, phone_verified_at: phoneVerifiedAt } = profile || {};

      return industries && languages && localization && typeof experienceInYears == "number" && phone && phoneVerifiedAt;
    }

    if (role == 'client_company_user' || role == 'candidate_user') {
      return true
    }

    if (user.status == 'active') {

      const { industries, languages, localization, experience_in_years: experienceInYears } = profile || {};

      return industries && languages && localization && typeof experienceInYears == "number";
    }

    return false;
  }
}
