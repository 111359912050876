import {Specification} from "@a3l/core";
import {OrganizationUserStatusEnum} from "@rex/organization/enums/organization-user-status.enum";
import {UserRoleEnum} from "@rex/core/enums/user-role.enum";

export class AccountActiveInOrganizationSpecification extends Specification<any> {
  organizationUserStatusEnum: typeof OrganizationUserStatusEnum = OrganizationUserStatusEnum;
  userRoleEnum: typeof UserRoleEnum = UserRoleEnum;

  isSatisfiedBy(candidate: any): boolean {
    const { role, status, organization, agreement } = candidate;

    if (role !== this.userRoleEnum.RECRUITER ) {
      return false;
    }

    if (status !== 'active') {
      return false;
    }


    const { industries, languages, localization, experience_in_years: experienceInYears } = candidate.profile || {};


    return organization && organization.status === this.organizationUserStatusEnum.ACTIVE && industries && languages && localization && typeof experienceInYears == "number";
  }
}
