import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LandingLanguageService {
  constructor(private router: Router, private translator: TranslateService) {}

  public activeLanguage: string = this.translator.currentLang;

  readonly urlTranslations: { [key: string]: { en: string, pl: string } } = {
    '': { en: '', pl: '' },
    'about': { en: 'about', pl: 'o-nas' },
    'contact-us': { en: 'contact-us', pl: 'kontakt' },
    'employer': { en: 'employer', pl: 'pracodawca' },
    'headhunter': { en: 'headhunter', pl: 'headhunter' },
    'candidate': { en: 'candidate', pl: 'kandydat' },
    'blog': { en: 'blog', pl: 'blog' },
    'faq': { en: 'faq', pl: 'pomoc' },
    'case-study': { en: 'case-study', pl: 'studia-przypadku' }
  };

  switchLanguage(targetLang: string) {
    let currentUrl = this.router.url.split('?')[0];
    let newUrl = '';

    if (targetLang === 'pl') {
      newUrl = this.translateUrl(currentUrl, 'pl');
    } else {
      newUrl = this.translateUrl(currentUrl, 'en');
    }

    window.location.href = newUrl;
  }

  isOnPage(page: string): boolean {
    const currentLang = this.activeLanguage;
    const translatedPage = this.translateUrl(page, currentLang);
    return this.router.url.includes(translatedPage);
  }

  getTranslatedUrl(route: string): string {
    return this.translateUrl(route, this.activeLanguage);
  }

  public translateUrl(currentUrl: string, targetLang: string): string {
    let parts = currentUrl.split('/').filter(Boolean);
    let currentLang: 'en' | 'pl' = parts[0] === 'pl' ? 'pl' : 'en';

    if (targetLang === 'pl' && currentLang === 'en') {
      parts.unshift('pl');
    }
    else if (targetLang === 'en' && currentLang === 'pl') {
      parts.shift();
    }

    parts = parts.map(part => {
      for (let key in this.urlTranslations) {
        if (currentLang === 'en' && this.urlTranslations[key].en === part && targetLang === 'pl') {
          return this.urlTranslations[key].pl;
        }
        if (currentLang === 'pl' && this.urlTranslations[key].pl === part && targetLang === 'en') {
          return this.urlTranslations[key].en;
        }
      }
      return part;
    });

    return '/' + parts.join('/');
  }
}
