import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'global-searchbar',
  templateUrl: 'searchbar.component.html',
  styleUrls: ['searchbar.component.scss'],
  host: {
    class: 'global-searchbar',
    '[class.global-searchbar--focused]': 'focused',
  },
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchbarComponent),
      multi: true
    }
  ]
})
export class SearchbarComponent implements ControlValueAccessor {
  /**
   * @var {string}
   */
  @Input()
  formControlName: string = '';

  /**
   * @var {string}
   */
  @Input()
  placeholder: string = 'jobboard::index.filters.main_search_placeholder';

  @Input()
  label: string = 'jobboard::index.filters.main_search_label';

  /**
   * @var {boolean}
   */
  focused: boolean = false;

  value: string = '';
  isDisabled: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInputChange(value: string) {
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }
}
